import { locales } from 'localization';

const DEFAULT_LOCALE_CODE = 'uk';

const enabledLocales = locales.filter((locale) => locale.code !== 'en').map((locale) => locale.code);

export {
  DEFAULT_LOCALE_CODE,
  enabledLocales,
};
