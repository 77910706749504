import { type I18nOptions } from 'vue-i18n';
import { logMissingTranslation } from '../utils/logMissingTranslation';

const createI18nConfig = (defaultLocaleCode: string): I18nOptions => ({
  datetimeFormats: {
    en: {
      short: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
    },
    uk: {
      short: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
    },
  },
  fallbackLocale: defaultLocaleCode,
  // It would add warnings to the console additionally to errors
  fallbackWarn: false,
  legacy: false,
  messages: {},
  missing: (missingLocale: string, missingKey: string) => {
    logMissingTranslation({
      currentFallbackLocale: defaultLocaleCode,
      missingKey,
      missingLocale,
    });
  },
  warnHtmlMessage: false,
});

export { createI18nConfig };
