import { Logger } from 'utils';
import type {
  LogMissingTranslationOptions,
  MissingTranslationOptions,
} from '../types/missingTranslations';

// Only log a missing translation key once.
const loggedMissingTranslationKeys: string[] = [];

const shouldLogMissingTranslation = ({
  missingKey,
  missingKeys,
}: MissingTranslationOptions) => !missingKeys.includes(missingKey);

const logMissingTranslation = ({
  missingKey,
  missingLocale,
}: LogMissingTranslationOptions) => {
  if (shouldLogMissingTranslation({
    missingKey,
    missingKeys: loggedMissingTranslationKeys,
    missingLocale,
  })) {
    Logger.error('missing-translation', {
      key: missingKey,
      locale: missingLocale,
    }, { forceConsoleError: true });

    loggedMissingTranslationKeys.push(missingKey);
  }
};

export { logMissingTranslation };
